var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
var SITE_KEY = "6LdoTSkTAAAAACxMJ2yf5S_CghOhFnyNkyT3ZFe9";
var ReCaptchaInput = /** @class */ (function (_super) {
    __extends(ReCaptchaInput, _super);
    function ReCaptchaInput(props) {
        var _this = _super.call(this, props) || this;
        _this.reCaptchaRef = React.createRef();
        _this.handleChange = function (value) {
            _this.setState({ reCaptchaValue: value });
            _this.triggerChange(value);
        };
        _this.triggerChange = function (changedValue) {
            if (_this.props.onChange) {
                _this.props.onChange(changedValue);
            }
        };
        _this.state = {
            reCaptchaValue: undefined,
        };
        return _this;
    }
    ReCaptchaInput.prototype.render = function () {
        var ref = this.reCaptchaRef;
        return (React.createElement(ReCAPTCHA, { theme: "dark", ref: ref, sitekey: SITE_KEY, onChange: this.handleChange }));
    };
    return ReCaptchaInput;
}(Component));
export default ReCaptchaInput;
