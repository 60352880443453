var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Dropdown from "antd/es/dropdown";
import "antd/es/dropdown/style/index.js";
import Menu from "antd/es/menu";
import "antd/es/menu/style/index.js";
import { MenuOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import "./NavBar.less";
var NavBar = /** @class */ (function (_super) {
    __extends(NavBar, _super);
    function NavBar(props) {
        var _this = _super.call(this, props) || this;
        _this.handleScroll = function () {
            _this.handleMenuVisibility(false);
        };
        _this.toggleMenu = function () {
            _this.handleMenuVisibility(!_this.state.popoverVisible);
        };
        _this.onClick = function (e) {
            var id = e.key;
            _this.props.userSelectedView(id);
            _this.handleMenuVisibility(false);
        };
        _this.state = { popoverVisible: false };
        return _this;
    }
    NavBar.prototype.componentDidMount = function () {
        window.addEventListener("scroll", this.handleScroll);
    };
    NavBar.prototype.componentWillUnmount = function () {
        window.removeEventListener("scroll", this.handleScroll);
    };
    NavBar.prototype.render = function () {
        return (React.createElement("div", { className: "nav" },
            React.createElement("div", { className: "content" },
                React.createElement("nav", { className: "nav-wrapper" },
                    this.getMenu("horizontal"),
                    React.createElement(Dropdown, { className: "nav-dropdown", overlay: this.getMenu("vertical"), placement: "bottomRight", visible: this.state.popoverVisible },
                        React.createElement(MenuOutlined, { className: "nav-hamburger", onClick: this.toggleMenu }))))));
    };
    NavBar.prototype.getMenu = function (mode) {
        var className = "nav-menu  " + mode;
        return (React.createElement(Menu, { className: className, onClick: this.onClick, mode: mode, selectedKeys: [this.props.selectedItemId] }, this.props.items.map(function (item) { return (React.createElement(Menu.Item, { className: "nav-item", key: item.id }, item.name)); })));
    };
    NavBar.prototype.handleMenuVisibility = function (popoverVisible) {
        this.setState({ popoverVisible: popoverVisible });
    };
    return NavBar;
}(Component));
export default NavBar;
