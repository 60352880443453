import Button from "antd/es/button";
import "antd/es/button/style/index.js";
import { MailOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import "./Home.less";
var IMG_CLASS = "lazy";
var Home = function (props) {
    // using React hook to ensure this code is only executed on hydrate (i.e, client side)
    useEffect(function () {
        document.addEventListener("DOMContentLoaded", function () {
            var lazyImage = document.querySelectorAll("img." + IMG_CLASS)[0];
            var lazyImageObserver = new IntersectionObserver(function (entries) {
                if (entries.length > 1) {
                    throw new Error("There can be only one!");
                }
                var entry = entries[0];
                if (entry.isIntersecting) {
                    var img = entry.target;
                    img.src = img.dataset.src || "";
                    img.srcset = img.dataset.srcset || "";
                    img.classList.remove(IMG_CLASS);
                    lazyImageObserver.unobserve(img);
                }
            });
            lazyImageObserver.observe(lazyImage);
        });
    });
    return (React.createElement("section", { className: "home" },
        React.createElement("div", { className: "content" },
            React.createElement("div", { className: "home-content" },
                React.createElement("div", { className: "home-hero-wrapper" },
                    React.createElement("img", { id: "home-hero-image", className: IMG_CLASS, alt: "ric", src: require("./img/me_placeholder.png"), "data-src": require("../../assets/img/me.jpeg") })),
                React.createElement("div", { className: "home-description materialize" },
                    React.createElement("h1", null, "Ricardo Marques"),
                    React.createElement("span", { className: "home-sub-title" }, "Full Stack Developer"),
                    React.createElement("p", null, "I specialize in Objected Oriented Development and Web Development. I stand for quality and love spending time optimizing every little piece of code."),
                    React.createElement("div", { className: "home-buttons" },
                        React.createElement(Button, { type: "primary", icon: React.createElement(MailOutlined, null), size: "large", onClick: props.goToContact }, "Contact me")))))));
};
export default Home;
