import { CalendarOutlined, EnvironmentOutlined } from "@ant-design/icons";
import React from "react";
import "./DateLocationEntry.less";
var DateLocationEntry = function (props) { return (React.createElement("div", { className: "date-location-entry" },
    React.createElement("span", { className: "text" },
        React.createElement(CalendarOutlined, null),
        " ",
        props.startingDate,
        " to ",
        props.endingDate),
    React.createElement("span", { className: "text" },
        React.createElement(EnvironmentOutlined, null),
        " ",
        props.location))); };
export default DateLocationEntry;
