import Card from "antd/es/card";
import "antd/es/card/style/index.js";
import { PlayCircleOutlined, GithubOutlined } from "@ant-design/icons";
import React from "react";
import ContentSection from "../common/ContentSection";
import "./Projects.less";
var projects = [
    {
        description: "A set of air quality sensors connected to an ESP32 MCU",
        image: require("./img/esp32.png"),
        repoUrl: "https://github.com/rjmarques/esp32-air-quality-sensor",
        title: "Esp32 Air Quality",
        url: "https://github.com/rjmarques/esp32-air-quality-sensor",
    },
    {
        description: "My own Terraform provisioned ECS cluster",
        image: require("./img/ecs.png"),
        repoUrl: "https://github.com/rjmarques/my-ecs-cluster",
        title: "My ECS Cluster",
        url: "https://github.com/rjmarques/my-ecs-cluster",
    },
    {
        description: "A twitter joke scraper running on ECS",
        image: require("./img/something-of-the-day.png"),
        repoUrl: "https://github.com/rjmarques/something-of-the-day",
        title: "Something of the day",
        url: "https://something.ricardomarques.dev/",
    },
    {
        description: "A Fractal WebAssembly Viewer",
        image: require("./img/wasm-mandel.png"),
        repoUrl: "https://github.com/rjmarques/webasm-mandelbrot",
        title: "WebAssembly Mandelbrot",
        url: "https://wasmmandel.ricardomarques.dev/",
    },
    {
        description: "My Solar System",
        image: require("./img/solar-system.png"),
        repoUrl: "https://github.com/rjmarques/SolarSystem",
        title: "WebGL Solar System",
        url: "https://solar.ricardomarques.dev/",
    },
];
var Projects = function () { return (React.createElement(ContentSection, { title: "Projects", subTitle: "Some projects I've done in my free time", wrapperClass: "projects" },
    React.createElement("div", { className: "projects-card-row materialize" }, projects.map(function (proj) { return (React.createElement(Card, { key: proj.title, hoverable: true, className: "projects-card", cover: React.createElement("img", { alt: "example", src: proj.image }), actions: [
            React.createElement("a", { key: "demo_url", href: proj.url, target: "_blank", rel: "noopener noreferrer" },
                React.createElement(PlayCircleOutlined, { className: "projects-action-icon" })),
            React.createElement("a", { key: "repo_url", href: proj.repoUrl, target: "_blank", rel: "noopener noreferrer" },
                React.createElement(GithubOutlined, { className: "projects-action-icon" })),
        ] },
        React.createElement("h4", null, proj.title),
        React.createElement("span", { className: "projects-card-subtitle" }, proj.description))); })))); };
export default Projects;
