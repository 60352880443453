import React from "react";
import ContentSection from "../common/ContentSection";
import "./Bio.less";
var Bio = function () { return (React.createElement(ContentSection, { title: "about me", subTitle: "Know me a bit better", wrapperClass: "bio" },
    React.createElement("div", { className: "bio-grid" },
        React.createElement("div", { className: "bio-picture" },
            React.createElement("img", { alt: "ric", src: require("../../assets/img/me.jpeg") })),
        React.createElement("div", null,
            React.createElement("div", { className: "bio-divider" }),
            React.createElement("div", { className: "bio-text materialize" },
                React.createElement("p", null,
                    "Hi, I'm Ricardo Marques. I'm a professional nerd that works on both the back and front end, while using a modern tech stack to make things go: ",
                    React.createElement("i", null, "Beep-Boop"),
                    "."),
                React.createElement("p", null,
                    "I started off my career as a backend Java developer, building JEE applications. Shortly after, my interest in all things Web related made me expand into becoming a Full Stack Developer. More recently I\u2019ve been working with Golang on the backend side of things, and with React and Typescript on the front-end bits -",
                    " ",
                    React.createElement("a", { href: "https://github.com/rjmarques/personal-website", target: "_blank", rel: "noopener noreferrer" }, "this website is a SSR React App with a Typescript backend"),
                    "."),
                React.createElement("p", null, "In my free time I dabble with tech I'm less familiar with. Recently it has been self-hosting, IoT home automation, and Arduino.")))))); };
export default Bio;
