var TOP_MARGIN_GHOST_DISTANCE = 100;
var TIME_INCREMENT = 20;
var DEFAULT_DURATION_MILLISECONDS = 800;
export var HEADER_HEIGHT_OFFSET = 82; // TODO get the actual value from the DOM
export function GetScrollTop(elem) {
    return elem.offsetTop - HEADER_HEIGHT_OFFSET;
}
export function CloseToPageTop(scrollingElem) {
    return scrollingElem.scrollTop < TOP_MARGIN_GHOST_DISTANCE;
}
export function AtView(scrollingElem, viewElem) {
    var viewOffsetTop = GetScrollTop(viewElem);
    var viewOffsetBottom = viewOffsetTop + viewElem.clientHeight;
    return (scrollingElem.scrollTop > viewOffsetTop &&
        scrollingElem.scrollTop < viewOffsetBottom);
}
export function InView(viewElem) {
    var rect = viewElem.getBoundingClientRect();
    var windowHeight = window.innerHeight;
    var top = rect.top + HEADER_HEIGHT_OFFSET;
    return top <= windowHeight && rect.top + rect.height >= 0;
}
export function ScrollTo(scrollingElem, viewElem, onComplete) {
    var duration = DEFAULT_DURATION_MILLISECONDS;
    var start = scrollingElem.scrollTop;
    var to = GetScrollTop(viewElem);
    var change = to + 2 - start; // the 1 is to include any border
    var currentTime = 0;
    var animateScroll = function () {
        currentTime += TIME_INCREMENT;
        var val = easeInOutQuad(currentTime, start, change, duration);
        scrollingElem.scrollTop = val;
        if (currentTime < duration) {
            window.requestAnimationFrame(animateScroll);
        }
        else {
            onComplete();
        }
    };
    animateScroll();
}
var easeInOutQuad = function (currentTime, startPos, deltaPos, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
        return (deltaPos / 2) * currentTime * currentTime + startPos;
    }
    currentTime--;
    return (-deltaPos / 2) * (currentTime * (currentTime - 2) - 1) + startPos;
};
