var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Button from "antd/es/button";
import "antd/es/button/style/index.js";
import Form from "antd/es/form";
import "antd/es/form/style/index.js";
import Input from "antd/es/input";
import "antd/es/input/style/index.js";
import message from "antd/es/message";
import "antd/es/message/style/index.js";
import React, { Component } from "react";
import ContentSection from "../common/ContentSection";
import ReCaptchaInput from "./ReCaptchaInput";
import "./Contact.less";
var FormItem = Form.Item;
var Contact = /** @class */ (function (_super) {
    __extends(Contact, _super);
    function Contact(props) {
        var _this = _super.call(this, props) || this;
        _this.formRef = React.createRef();
        _this.onFinish = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var hide, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hide = message.loading("Sending message...", 0);
                        this.setState({ isSending: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.props.sendMessage(values.name, values.email, values.message, values.captcha, values.subject, values.company)];
                    case 2:
                        _a.sent();
                        this.formRef.current.resetFields([
                            "name",
                            "email",
                            "message",
                            "subject",
                            "company",
                            "captcha",
                        ]);
                        message.success("Thank you for your message! I'll come back to you as soon as possible.", 5);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        message.error("Uh oh...Please refresh the page and try again", 5);
                        return [3 /*break*/, 5];
                    case 4:
                        hide();
                        this.setState({ isSending: false });
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.onFinishFailed = function (errorInfo) {
            console.log("Failed:", errorInfo);
        };
        _this.validateReCaptcha = function (rule, value) {
            return value
                ? Promise.resolve()
                : Promise.reject("You're not a bot, are you?");
        };
        _this.state = {
            isSending: false,
        };
        return _this;
    }
    Contact.prototype.render = function () {
        return (React.createElement(ContentSection, { title: "Contact", subTitle: "Want to get in touch? Leave me a message", wrapperClass: "contact" },
            React.createElement(Form, { ref: this.formRef, name: "contact-form", onFinish: this.onFinish, onFinishFailed: this.onFinishFailed, className: "contact-form materialize" },
                React.createElement(FormItem, { name: "name", rules: [
                        {
                            message: "What's your name?",
                            required: true,
                        },
                    ] },
                    React.createElement(Input, { size: "large", placeholder: "Your Name *" })),
                React.createElement(FormItem, { name: "email", rules: [
                        {
                            message: "The input is not valid Email",
                            type: "email",
                        },
                        {
                            message: "How do I message you back?",
                            required: true,
                        },
                    ] },
                    React.createElement(Input, { size: "large", type: "text", placeholder: "Email *" })),
                React.createElement(FormItem, { name: "subject" },
                    React.createElement(Input, { size: "large", placeholder: "Subject" })),
                React.createElement(FormItem, { name: "company" },
                    React.createElement(Input, { size: "large", type: "text", placeholder: "Company" })),
                React.createElement(FormItem, { className: "full-row", name: "message", rules: [
                        {
                            message: "Did you want to tell me something?",
                            required: true,
                        },
                    ] },
                    React.createElement(Input.TextArea, { className: "contact-text-area", placeholder: "Message *", rows: 6 })),
                React.createElement(FormItem, { name: "captcha", rules: [{ validator: this.validateReCaptcha }], initialValue: undefined },
                    React.createElement(ReCaptchaInput, null)),
                React.createElement(FormItem, { className: "full-row" },
                    React.createElement(Button, { type: "primary", htmlType: "submit", size: "large", loading: this.state.isSending }, "send message")))));
    };
    return Contact;
}(Component));
export default Contact;
