var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import Bio from "./bio/Bio";
import Contact from "./contact/Contact";
import Education from "./education/Education";
import Experience from "./experience/Experience";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import NavBar from "./nav-bar/NavBar";
import Projects from "./projects/Projects";
import Publications from "./publications/Publications";
import Skills from "./skills/Skills";
import { AtView, CloseToPageTop, HEADER_HEIGHT_OFFSET, InView, ScrollTo, } from "../services/Scroll";
import SendMessage from "../services/SendMessage";
import "./App.less";
// views that trigger status a nav change
var views = [
    {
        id: "HOME",
        name: "home",
        ref: React.createRef(),
    },
    {
        id: "BIO",
        name: "bio",
        ref: React.createRef(),
    },
    {
        id: "EXPERIENCE",
        name: "experience",
        ref: React.createRef(),
    },
    {
        id: "PROJECTS",
        name: "projects",
        ref: React.createRef(),
    },
    {
        id: "ACADEMIC",
        name: "academic",
        ref: React.createRef(),
    },
    {
        id: "CONTACT",
        name: "contact",
        ref: React.createRef(),
    },
];
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.contactViewSelected = function () {
            _this.viewSelected(views[views.length - 1].id);
        };
        _this.viewSelected = function (viewId) {
            if (_this.state.isScrolling) {
                return;
            }
            _this.setSelectedView(viewId);
            var selectedView = views.find(function (v) { return v.id === viewId; });
            if (selectedView && selectedView.ref.current && document.scrollingElement) {
                _this.setIsScrolling(true);
                ScrollTo(document.scrollingElement, selectedView.ref.current, function () {
                    _this.setIsScrolling(false);
                });
            }
        };
        _this.handleScroll = function () {
            _this.setHeaderClass();
            _this.setViewed();
            if (_this.state.isScrolling) {
                return;
            }
            for (var _i = 0, views_1 = views; _i < views_1.length; _i++) {
                var view = views_1[_i];
                var cur = view.ref.current;
                if (cur &&
                    document.scrollingElement &&
                    AtView(document.scrollingElement, cur)) {
                    _this.setSelectedView(view.id);
                    break;
                }
            }
        };
        _this.setViewed = function () {
            for (var _i = 0, views_2 = views; _i < views_2.length; _i++) {
                var view = views_2[_i];
                if (_this.isViewVisited(view.id)) {
                    continue;
                }
                var cur = view.ref.current;
                if (cur && InView(cur)) {
                    _this.state.visitedViewsByID.set(view.id, true);
                    _this.setState({ visitedViewsByID: _this.state.visitedViewsByID });
                }
            }
        };
        _this.state = {
            atViewId: views[0].id,
            isGhostHeader: true,
            isScrolling: false,
            visitedViewsByID: new Map(),
        };
        return _this;
    }
    App.prototype.render = function () {
        return (React.createElement("div", { className: "app" },
            React.createElement("header", { className: this.getHeaderClass() },
                React.createElement(NavBar, { items: views, selectedItemId: this.state.atViewId, userSelectedView: this.viewSelected })),
            React.createElement("div", { id: views[0].id, ref: views[0].ref, className: this.isViewVisited(views[0].id) ? "visited" : "" },
                React.createElement(Home, { goToContact: this.contactViewSelected })),
            React.createElement("div", { className: "app-main-content" },
                React.createElement("div", { id: views[1].id, ref: views[1].ref, className: this.isViewVisited(views[1].id) ? "visited" : "" },
                    React.createElement(Bio, null),
                    React.createElement(Skills, null)),
                React.createElement("div", { id: views[2].id, ref: views[2].ref, className: this.isViewVisited(views[2].id) ? "visited" : "" },
                    React.createElement(Experience, null)),
                React.createElement("div", { id: views[3].id, ref: views[3].ref, className: this.isViewVisited(views[3].id) ? "visited" : "" },
                    React.createElement(Projects, null)),
                React.createElement("div", { id: views[4].id, ref: views[4].ref, className: this.isViewVisited(views[4].id) ? "visited" : "" },
                    React.createElement(Publications, null),
                    React.createElement(Education, null)),
                React.createElement("div", { id: views[5].id, ref: views[5].ref, style: {
                        minHeight: "calc(100vh - " + (HEADER_HEIGHT_OFFSET - 2) + "px)",
                    }, className: this.isViewVisited(views[5].id) ? "visited" : "" },
                    React.createElement(Contact, { sendMessage: SendMessage }),
                    React.createElement(Footer, null)))));
    };
    App.prototype.componentDidMount = function () {
        window.addEventListener("scroll", this.handleScroll);
        this.setViewed();
    };
    App.prototype.componentWillUnmount = function () {
        window.removeEventListener("scroll", this.handleScroll);
    };
    App.prototype.getHeaderClass = function () {
        return this.state.isGhostHeader ? "app-header ghost" : "app-header";
    };
    App.prototype.setHeaderClass = function () {
        if (document.scrollingElement &&
            CloseToPageTop(document.scrollingElement)) {
            this.setIsGhostHeader(true);
        }
        else {
            this.setIsGhostHeader(false);
        }
    };
    App.prototype.setSelectedView = function (viewId) {
        this.setState({ atViewId: viewId });
    };
    App.prototype.setIsScrolling = function (isScrolling) {
        this.setState({ isScrolling: isScrolling });
    };
    App.prototype.setIsGhostHeader = function (isGhostHeader) {
        this.setState({ isGhostHeader: isGhostHeader });
    };
    App.prototype.isViewVisited = function (id) {
        return this.state.visitedViewsByID.get(id) || false;
    };
    return App;
}(Component));
export default App;
