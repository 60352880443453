import { LinkedinOutlined, GithubOutlined } from "@ant-design/icons";
import React from "react";
import "./Footer.less";
var Footer = function () { return (React.createElement("footer", { className: "footer" },
    React.createElement("div", { className: "content" },
        React.createElement("div", { className: "footer-info" },
            React.createElement("div", { className: "footer-links" },
                React.createElement("a", { href: "https://github.com/rjmarques", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(GithubOutlined, { type: "github" })),
                React.createElement("a", { href: "https://linkedin.com/in/ricardo-marques-48568b44", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(LinkedinOutlined, null))),
            React.createElement("small", { className: "footer-copy" },
                "\u00A9 Copyright ",
                new Date().getFullYear(),
                " Ricardo Marques"))))); };
export default Footer;
