import React from "react";
import ContentSection from "../common/ContentSection";
import "./Publications.less";
var publications = [
    {
        conference: "Sac'14",
        paper: "On the Support of Task-Parallel Algorithmic Skeletons for Multi-GPU Computing",
        urls: ["http://nova-lincs.di.fct.unl.pt/publication/1329"],
    },
    {
        conference: "Euro-Par 2013",
        paper: "Algorithmic Skeleton Framework for the Orchestration of GPU Computations",
        urls: ["http://link.springer.com/chapter/10.1007%2F978-3-642-40047-6_86"],
    },
];
var Publications = function () { return (React.createElement(ContentSection, { title: "Publications", subTitle: "Papers I have published", wrapperClass: "publications" },
    React.createElement("ul", { className: "materialize" }, publications.map(function (pub) { return (React.createElement("li", { key: pub.conference, className: "publications-entry" },
        React.createElement("div", { className: "app-card-wide" },
            React.createElement("h3", null, pub.conference),
            React.createElement("div", { className: "app-card-subtitle" },
                React.createElement("span", null, pub.paper)),
            pub.urls.map(function (url) { return (React.createElement("div", { key: url },
                React.createElement("a", { href: url, target: "_blank", rel: "noopener noreferrer" }, url))); })))); })))); };
export default Publications;
