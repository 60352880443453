export default [
    {
        company: "Longshot Systems",
        endingDate: "Now",
        jobTitle: "senior full stack developer",
        location: "London",
        roles: [
            "Lead the UI development of internal and customer facing web UIs: including defining CI/CD workflows, selecting technologies, and UI/UX design.",
            "Ownership over greenfield full stack projects (React+Golang+Postgres), where I was responsible for all development phases, including: requirement gathering, architecture design, full stack implementation, deployment and maintenance.",
            "Spearheaded the introduction of Docker as the execution platform for Longshot’s microservices, in place of Systemd deployments. Additionally, designed and developed a build & deployment CLI tool that streamlined the Docker development workflow. Which greatly facilitated the transition to Docker, from the developer’s perspective.",
            "Managed software developer interns. Which included defining the interview process and carrying out the end-to-end technical hiring process. Writing and allocating tasks for them, that fitted in the team’s broader goals. Reviewing their progress, and guiding them as needed. And also, mentoring them on Software Engineering best practices and techniques.",
            "Worked on various complex data processing pipeline applications. For example, one that aggregated sport information across different third-party systems (bookies) and produced internal representations, which abstracted the rest of the system from the various bookie-specific nomenclatures. This was by achieved by producing a proximity graph for sporting events that were closely related to one another, and required high amounts of integration/blackbox tests (harnessing docker-compose) to ensure ongoing reliability.",
            "Responsible for improving Longshot’s Authentication and Authorization systems and tooling. In particular, evaluating what OpenID Connect system would fit into the wider architecture. As well as, being responsible for overseeing the introducing of the selected system into production.",
        ],
        startingDate: "Nov 2018",
        technologies: [
            "React",
            "Golang",
            "PostgreSQL",
            "Typescript",
            "Docker",
            "Node.js",
            "AWS EC2",
        ],
        url: "https://www.longshotsystems.co.uk/",
    },
    {
        company: "Hyperglance",
        endingDate: "Oct 2018",
        jobTitle: "senior full stack developer",
        location: "London",
        roles: [
            "Enhanced the Hyperglance JEE server-side feature-base, by developing core modules. The latter spanned the full server-side architectural stack, ranging from JAX-RS Rest Endpoints, and EJB enhanced business-logic features, to JPA persistence components",
            "Kick-started conceptualization and development of Hyperglance’s Web GUI. This included selecting what technologies to use, defining a build used by developers and CI tools, developing the client’s architectural backbone (that integrated a WebGL module for data visualization, and outlining coding practices/standards. Moreover, was in charge of all technical aspects regarding the Web GUI and its integration with the Hyperglance backend. All the while being the only web developer on the team.",
            "Other responsibilities included: interviewing both frontend and backend developer candidates, attending conferences, speaking to customers, mentoring new-hires, and evaluating codebase health.",
        ],
        startingDate: "Jul 2014",
        technologies: [
            "AngularJs",
            "Javascript",
            "Three.js",
            "Java EE",
            "EJBs",
            "PostgreSQL",
            "Node.js",
            "Wildfly",
            "AWS EC2",
        ],
        url: "https://www.hyperglance.com/",
    },
    {
        company: "Guidewire Software",
        endingDate: "Jun 2014",
        jobTitle: "solutions consultant",
        location: "Dublin",
        roles: [
            "Partly designed, developed and maintained an efficient and fault-tolerant configuration of a multi-tiered J2EE Guidewire billing product on two tier one Insurance companies, that would later handle millions of dollars in early revenue.",
            "Designed and developed integration points (e.g., SOAP, JMS) between the client server application and external third-party services, that provided scalable and concurrent bidirectional data flows based on real-time monetary client transactions.",
            "Conceptualized and implemented a modular JUnit framework for populating Guidewire’s product database with test data. The framework improved the JUnit development productivity in 50%, on average.",
        ],
        startingDate: "Nov 2012",
        technologies: ["Java", "JMS", "SOAP", "Rest", "PostgreSQL"],
        url: "https://www.guidewire.com/",
    },
];
