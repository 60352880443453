import React from "react";
import ContentSection from "../common/ContentSection";
import DateLocationEntry from "../common/DateLocationEntry";
import "./Education.less";
var Education = function () { return (React.createElement(ContentSection, { title: "Education", subTitle: "My academic background", wrapperClass: "education" },
    React.createElement("ul", { className: "materialize" },
        React.createElement("li", { className: "education-entry" },
            React.createElement("div", { className: "app-card-wide" },
                React.createElement("h3", null,
                    "MSc In Computer Science -",
                    " ",
                    React.createElement("a", { href: "https://www.fct.unl.pt/en", target: "_blank", rel: "noopener noreferrer" }, "New University of Lisbon")),
                React.createElement("div", { className: "app-card-subtitle" },
                    React.createElement(DateLocationEntry, { startingDate: "2010", endingDate: "2012", location: "Lisbon" })),
                React.createElement("div", null,
                    "Dissertation:",
                    " ",
                    React.createElement("a", { href: "https://run.unl.pt/handle/10362/8382", target: "_blank", rel: "noopener noreferrer" }, "Algorithmic skeleton framework for the orchestration of GPU computations")),
                React.createElement("div", null,
                    "Source Code:",
                    " ",
                    React.createElement("a", { href: "https://bitbucket.org/MarrowTeam/marrow", target: "_blank", rel: "noopener noreferrer" }, "https://bitbucket.org/MarrowTeam/marrow")))),
        React.createElement("li", { className: "education-entry" },
            React.createElement("div", { className: "app-card-wide" },
                React.createElement("h3", null,
                    "BSc In Computer Science -",
                    " ",
                    React.createElement("a", { href: "https://www.fct.unl.pt/en", target: "_blank", rel: "noopener noreferrer" }, "New University of Lisbon")),
                React.createElement("div", { className: "app-card-subtitle" },
                    React.createElement(DateLocationEntry, { startingDate: "2007", endingDate: "2010", location: "Lisbon" }))))))); };
export default Education;
