import React from "react";
import ContentSection from "../common/ContentSection";
import DateLocationEntry from "../common/DateLocationEntry";
import content from "./content";
import "./Experience.less";
var experienceEntries = content;
var Experience = function () {
    return (React.createElement(ContentSection, { title: "Experience", subTitle: "What I have done professionally", wrapperClass: "experience" },
        React.createElement("div", { className: "experience-timeline materialize" },
            React.createElement("ul", null, experienceEntries.map(function (entry) { return (React.createElement("li", { key: entry.jobTitle + entry.company, className: "experience-timeline-entry" },
                React.createElement("div", { className: "app-card-wide" },
                    React.createElement("h3", null,
                        entry.jobTitle,
                        " -",
                        " ",
                        React.createElement("a", { href: entry.url, target: "_blank", rel: "noopener noreferrer" }, entry.company)),
                    React.createElement("div", { className: "app-card-subtitle" },
                        React.createElement(DateLocationEntry, { startingDate: entry.startingDate, endingDate: entry.endingDate, location: entry.location })),
                    React.createElement("div", { className: "experience-roles" }, entry.roles.map(function (role, index) { return (React.createElement("p", { key: "p_" + index }, role)); })),
                    React.createElement("div", { className: "experience-technologies" },
                        React.createElement("h4", null, "main tools: "),
                        React.createElement("div", { className: "experience-technologies-items" }, entry.technologies.map(function (tech) { return (React.createElement("span", { key: tech }, tech)); })))))); })))));
};
export default Experience;
